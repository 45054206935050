import FellowshipHeader from '@components/Fellowship/Header/index';
import Header from '@components/Header/index';
import MyTypeform from '@components/MyTypeform/index';
import { navigate } from 'gatsby'; //import navigate from gatsby
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { GTM, getExperimentAndVariant } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import countries from '../../../utils/i18n-countries';
import db from '../../data/db.json';
import CircularProgress from '@mui/material/CircularProgress';
import Navigation from '@components/Navigation/index';
import { use100vh } from 'react-div-100vh';

export default function Registration(props) {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const [loading, setLoading] = useState(true);
  const height = use100vh();

  const [submitted, setSubmitted] = useState(false);
  let countryTypeform = null;
  if (countries.includes(state.country)) {
    //  && props.location?.state?.type == 'FULL_STACK'
    let page = db.strapi_metadata.programs.find(
      (ele) => ele.countryName.split('__')[1] == state.country,
    );
    if (page && props.location?.state?.link !== page.typeform) {
      countryTypeform = page.typeform;
    }
  }
  useEffect(() => {
    if (!props.location.state) {
      navigate('/');
    }
    dispatch({
      type: 'USER_IS_APPLYING',
      payload: false,
    });

    if (typeof window !== 'undefined') {
      document.getElementsByTagName('html')[0].style.overscrollBehaviorY =
        'contain';
    }
    return () => {
      if (typeof window !== 'undefined') {
        document.getElementsByTagName('html')[0].style.overscrollBehaviorY =
          'auto';
      }
    };
  }, []);

  const getHeader = (type) => {
    switch (type) {
      case 'BRAND_PARTNER':
        return <></>;
      case 'FELLOWSHIP':
        return <FellowshipHeader disableCTA={true}></FellowshipHeader>;
      default:
        return <Header scrolled={true} disableCTA={true}></Header>;
    }
  };
  return (
    <>
      {props.location.state && (
        <>
          <Navigation hideTopStrip />

          <div style={{ height: height, width: '100vw' }}>
            {submitted ? (
              <div className="bg-diwali-sale-neutral-200 flex h-screen w-full items-center justify-center">
                <CircularProgress
                  thickness={5}
                  disableShrink
                  color="secondary"
                />
              </div>
            ) : (
              <>
                {loading && (
                  <div className="bg-diwali-sale-neutral-200 fixed top-0 right-0 left-0 z-10 flex h-full w-full items-center justify-center">
                    <CircularProgress
                      thickness={5}
                      disableShrink
                      color="secondary"
                    />
                  </div>
                )}
                <div
                  className={`${
                    loading ? 'invisible' : 'visible pt-[60px]'
                  } h-full w-full`}
                >
                  <MyTypeform
                    url={countryTypeform || props.location?.state?.link}
                    onReady={() => {
                      setLoading(false);
                    }}
                    onSubmit={(values) => {
                      setSubmitted(true);
                      GTM.track(gtmEvents.APPLIED_TO_PROGRAM, {
                        programType:
                          props.location.state && props.location.state.type,
                      });

                      let payload = {};
                      let applicationId = nanoid();
                      payload[
                        btoa(
                          props.location.state.type +
                            (countryTypeform || props.location?.state?.link),
                        )
                      ] = {
                        submitted: Date.now(),
                        id: applicationId,
                        type: props.location.state.type,
                        location: props.location.state.location,
                        ...(props.location?.state?.secondaryType && {
                          secondaryType: props.location.state.secondaryType,
                        }),
                        ...(props.location?.state?.program && {
                          program: props.location.state.program,
                        }),
                      };
                      dispatch({
                        type: 'SET_REGISTRATION_DETAILS',
                        payload,
                      });

                      window.open(`/registered/?id=${applicationId}`, '_self');
                    }}
                    params={{
                      ...(getExperimentAndVariant().isExperimentRunning() && {
                        experiment:
                          getExperimentAndVariant().mainAbExperimentId +
                            ':' +
                            getExperimentAndVariant().variant || 0,
                      }),
                      country: state.country,
                    }}
                  ></MyTypeform>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
